:root:root {
    --adm-color-primary: #0097A7;
}

.App {
    text-align: center;
}


.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.flex-row {
    display: flex;
    align-items: center;
}

.flex-col {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.flex-center {
    align-items: center;
    justify-content: center;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: flex-end;
}

.justify-between {
    justify-content: space-between;
}

.flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.font-size40 {
    font-size: 40px;
    font-family: SourceHanSansCN, SourceHanSansCN-Bold;
}

.font-size24 {
    font-size: 24px;
    font-family: SourceHanSansCN, SourceHanSansCN-Bold;
}


.font-size32 {
    font-size: 32px;
    font-family: SourceHanSansCN, SourceHanSansCN-Bold;
}


.font-bold {
    font-weight: 700;
}

.colorDefault{
    color: var(--adm-color-primary);
}


.color263238 {
    color: #263238;
}

.colorD32F2F {
    color: #D32F2F;
}

.color666666 {
    color: #666666;
}

.color52C41A {
    color: #52C41A;
}

.color999 {
    color: #999999;
}

.color333 {
    color: #333333;
}

.text-center {
    text-align: center;
}


.text-end {
    text-align: right;
}

.height100p {
    height: 100%;
}

.width100p {
    width: 100%;
}

.font-size15 {
    font-size: 15px;
    font-family: SourceHanSansCN, SourceHanSansCN-Bold;
}

.font-size18 {
    font-size: 18px;
    font-family: SourceHanSansCN, SourceHanSansCN-Bold;
}

.font-size13 {
    font-size: 13px;
    font-family: SourceHanSansCN, SourceHanSansCN-Bold;
}

.font-size12 {
    font-size: 12px;
    font-family: SourceHanSansCN, SourceHanSansCN-Bold;
}


.font-size10 {
    font-size: 10px;
    font-family: SourceHanSansCN, SourceHanSansCN-Bold;
}

.mobile-input-text-end input {
    text-align: right !important;
}

.width50p {
    width: 50%;
}


.text-underline{
    text-decoration: underline;
}


.scroll-touch{
    -webkit-overflow-scrolling: touch;
    -webkit-text-size-adjust: none;
}


.check-btn{
    border-radius: 8px;
    font-size: 13px;
    padding: 6px 10px;
}

.check-btn-normal{
    background: #0097A7;
    color: #ffffff;
}


.check-btn-selected{
    background: #F5F5F5;
    color: #666666;
}

.border-radius0{
    border-radius: 0 !important;
}

.bg-white{
    background: #fff;
}
